<!-- 设备管理-备件管理-备件购置 -->
<template>
    <div class="page_container ">
        <div class="box_head1">
            <span>备件购置</span>
            <div class="flex  align_center mg_left_50 fn_size14">
                <div class="">仓库：</div>
                <!-- <el-input v-model="queryValue" style="width: 180px;" placeholder="请输入"></el-input> -->
                <el-select v-model="queryValue" clearable placeholder="请选择" class="width_150">
                    <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key"
                        :value="item.value"> </el-option>
                </el-select>
                <el-button type="primary" class=" mg_left_10" size="medium" @click="queryTest">查询
                </el-button>
            </div>
        </div>
        <div class="bgc_FFFFFF pdl30 pdr30">
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_140 bgc_03CABE buttonBorder" style="background-color: #03CABE;" @click="gotoAdd">添加
                    </el-button>
                     <el-button type="primary" class="mg_left_10" @click="exportRepairRecord"
                            style=" background: #03CABE;border: none;">导出</el-button>
                    <!-- <el-button type="primary" icon="el-icon-delete"
                        class="width_140 mg_left_10 bgc_F75753 buttonBorder" style="background-color: #F75753;" @click="deleteNum">删除</el-button> -->
                </div>
                <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> -->
            </div>
            <!-- table -->
            <div class="height_500">
                <el-table ref="multipleTable" :data="dataList" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" @row-click="rowClick" height="100%"
                    :default-sort="{prop: 'date', order: 'descending'}" @selection-change="handleSelectionChange"
                     @row-dblclick="dblclickGotoFaultDetails" border=""  id="repairPlanTable">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width="" show-overflow-tooltip ></el-table-column> -->
                    <!-- <el-table-column prop="authSort" label="当前审批顺序" width="" show-overflow-tooltip  ></el-table-column> -->
                    <!-- <el-table-column prop="isAuth" label="是否审批" show-overflow-tooltip  :formatter="formatBoolean"></el-table-column> -->
                    <!-- <el-table-column prop="doAuth" label="是否可以进行审批"  :formatter="formatBoolean"></el-table-column> -->
                    <el-table-column prop="applyTime" label="申请时间" :formatter="formatDate" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="applyPerson" label="申请人"></el-table-column>
                    <el-table-column prop="warehouse" label="申请仓库"></el-table-column>
                    <el-table-column prop="department" label="申请部门"></el-table-column>
                    <el-table-column prop="orderStatus_name" label="工单状态"></el-table-column>
                    <el-table-column prop="authRoleName" label="当前审批角色"></el-table-column>
                    <el-table-column prop="totalPrice" label="申购总价"></el-table-column>
                    <!-- <el-table-column prop="remarks" label="备注"  ></el-table-column> -->
                    <!-- <el-table-column prop="address" label="备件图片" show-overflow-tooltip></el-table-column> -->
                    <el-table-column label="操作" width="220">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoDetails(scope.row.ID)">详细</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0" v-if="scope.row.orderStatus = 0">
                                <span style="border-bottom: 1px solid #009BFD;" @click="edit(scope.row.ID)">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="deleteNum(scope.row.ID)">删除</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span v-if="scope.row.doAuth===true" style="border-bottom: 1px solid #009BFD;"
                                    @click="approvalOperation(scope.row)">审批</span>
                                <!-- <span v-else style="border-bottom: 1px solid #009BFD;">已审批</span> -->
                            </el-button>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="操作">
                        <template slot-scope="scope">
                            
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_between align_start">
                <div class="color_808080 fn_size14 mg_top_19"></div>
                <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                    :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            </div>
            <!-- 选择框 -->
            <div class="bgc_E2F0FE flex align_end mg_top_24 height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">申请明细
                </div>
            </div>
            <!-- table -->
            <div class="mg_top_22">
                <el-table ref="multipleTable" :data="detailList" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" height="290" :default-sort="{prop: 'date', order: 'descending'}">
                    <!-- <el-table-column type="selection" width="55"></el-table-column> -->
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="orderID" label="工单编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sparePartID" label="备件编号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sparePartName" label="备件名称" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="sparePartModel" label="备件型号" width="" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="unit" label="单位" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="applyQuan" label="申请数量" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="unitPrice" label="单价" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="totalPrice" label="申请总价" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="remarks" label="备注" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>
            <!-- 页码 -->
            <div class="flex justify_between align_start">
                <div class="color_808080 fn_size14 mg_top_19"></div>
                <el-pagination layout="prev, pager, next" :total="pageQuantity1*pageSize1" :page-size="pageSize1"
                    :current-page.sync="currentPage1" @current-change="handleCurrentChange1"></el-pagination>
            </div>
            <el-dialog title="编辑" :visible.sync="dialogVisibleEdit" width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">编辑</div> -->
                <div class=" flex justify_center align_center mg_top_15">
                    <el-form :inline="true" :model="editInline" label-width="110px" style="width: 65%;">
                        <el-form-item label="工单编号：">
                            <el-input v-model="detailInline.orderID" placeholder="工单编号" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="项目编号：" >
                            <el-input v-model="detailInline.projectID" placeholder="项目编号" class="width_180" disabled></el-input>
                        </el-form-item> -->
                        <el-form-item label="当前审批顺序：">
                            <el-input v-model="detailInline.authSort" placeholder="当前审批顺序" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="申请仓库：">
                            <!-- <el-input v-model="editInline.warehouse" placeholder="申请仓库" class="width_180"></el-input> -->
                            <el-select v-model="editInline.warehouse" placeholder="请选择仓库" class="width_180">
                                <el-option v-for="item in warehouseSelectList" :key="item.value" :label="item.key"
                                    :value="item.value"> </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="申请部门：">
                            <el-input v-model="editInline.department" placeholder="申请部门" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="领用总价：">
                            <el-input v-model="editInline.totalPrice" placeholder="总价" class="width_180"></el-input>
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input type="textarea" rows="3" v-model="editInline.remarks" placeholder="备注区域"
                                class="width_180"></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="cancel">取 消</el-button>
                    <el-button type="primary" @click="editConfirm">确 定</el-button>
                </div>
            </el-dialog>
            <el-dialog title="详情" :visible.sync="dialogVisibleDetail" width="30%">
                <!-- <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">详情</div> -->
                <div class=" flex justify_center align_center mg_top_15">
                    <el-form :inline="true" :model="detailInline" label-width="150px" style="width: 65%;">
                        <el-form-item label="工单编号：">
                            <el-input v-model="detailInline.orderID" placeholder="工单编号" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <!-- <el-form-item label="项目编号：" >
                            <el-input v-model="detailInline.projectID" placeholder="项目编号" class="width_180" disabled></el-input>
                        </el-form-item> -->
                        <el-form-item label="当前审批顺序：">
                            <el-input v-model="detailInline.authSort" placeholder="当前审批顺序" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="申请仓库：">
                            <el-input v-model="detailInline.warehouse" placeholder="申请仓库" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="申请部门：">
                            <el-input v-model="detailInline.department" placeholder="申请部门" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="领用总价：">
                            <el-input v-model="detailInline.totalPrice" placeholder="总价" class="width_180" disabled>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="备注：">
                            <el-input type="textarea" rows="3" v-model="detailInline.remarks" placeholder="备注区域"
                                class="width_180" disabled></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleDetail = false">确定</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="dialogVisibleAuth" width="30%">
                <div slot="title" class="fn_size18 color_000000 flex justify_center font_bold">审批</div>
                <div class="height_110 flex justify_center align_center">
                    <el-radio-group v-model="approvalListValue" class="">
                        <el-radio v-for="item in approvalList" :key="item.value" :label="item.value">
                            {{item.description}}
                        </el-radio>
                    </el-radio-group>
                </div>
                <div class="flex justify_center pdb_20 ">
                    <el-button @click="dialogVisibleAuth = false">取 消</el-button>
                    <el-button type="primary" @click="confirm">确 定</el-button>
                </div>
            </el-dialog>

        </div>
    </div>
</template>

<script>
    //   import innerTopNav from '../../../components/innerTopNav'
    import {
        sparePartWarehouseQuery, //仓库下拉框 接口
        sparePartPurchaseMultiAuthQueryLimit,
        sparePartPurchaseQuery,
        sparePartPurchaseDelete,
        sparePartPurchaseUpdate,
        sparePartPurchaseAuthorize,
        sparePartPurchaseInsert,
        sparePartPurchaseDetailQueryLimit,
        multiAuthorizeResultQuery1
    } from "@/api/api_deviceManagement";

    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                userID: '',
                queryValue: '',
                warehouseSelectList: [], //仓库下拉框 使用的数组
                tabIndex: 0,
                value: '',
                input: '',
                dialogTableVisible: false,
                options: [],
                // options: [{
                //     value: '选项1',
                //     label: '黄金糕'
                // }, {
                //     value: '选项2',
                //     label: '双皮奶'
                // }, {
                //     value: '选项3',
                //     label: '蚵仔煎'
                // }, {
                //     value: '选项4',
                //     label: '龙须面'
                // }, {
                //     value: '选项5',
                //     label: '北京烤鸭'
                // }],
                tableData: [{
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }],
                approvalListValue: undefined,
                dialogVisibleEdit: false,
                dialogVisibleAuth: false,
                dialogVisibleDetail: false,
                updatedResult: '',
                editInline: {},
                detailInline: {},
                rowData: [],
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,
                currentPage1: 1,
                pageSize1: 5,
                pageQuantity1: 0,

                permission: {},
                userID: '',
                dataList: [],
                detailList: [],
                queryData: {},
                approvalList: [],
                normalID: '',
                permission: {},
                warehouse: '',
                ID: '',
            }
        },
        mounted() {
            if (localStorage.getItem('permission') != null) {
                // console.log(JSON.parse(localStorage.getItem('permission')));
                this.permission = JSON.parse(localStorage.getItem('permission'))
            }
            if (localStorage.getItem('userInfo') != null) {
                let userInfo = JSON.parse(localStorage.getItem('userInfo'))
                this.userID = userInfo.userID
            }
            
            this.sparePartPurchaseMultiAuthQueryLimit()
            // this.sparePartPurchaseDetailQueryLimit()

            //页面一加载，就初始化仓库下拉框的数据
            this.sparePartWarehouseQuery();
        },
        methods: {

            //仓库下拉框
            async sparePartWarehouseQuery() {

                //封装参数
                var param = {
                    userID: this.userID,
                };

                //调用接口
                const selectRes = await sparePartWarehouseQuery(param);

                //处理返回值
                if (selectRes) { //如果有返回值
                    this.warehouseSelectList = selectRes.data;
                }
            },

            //查询按钮
            queryTest() {
                this.warehouse = this.queryValue
                this.currentPage = 1;
                this.sparePartPurchaseMultiAuthQueryLimit()
            },

            //全部数据
            async sparePartPurchaseMultiAuthQueryLimit() {

                /*                 if (this.queryValue!='') {
                                    this.currentPage = 1;
                                } */
                var param = {
                    // orderID:,
                    // startApplyTime:,
                    // endApplyTime:,
                    // applyPerson:,
                    // warehouse:this.queryValue,
                    // department:,
                    // orderStatus:,
                    // authSort:,
                    // isAuth:,
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                    userID: this.userID,
                    // userID:this.userID,
                }
                if (this.queryValue != '') {
                    param.warehouse = this.queryValue
                }
                const selectRes = await sparePartPurchaseMultiAuthQueryLimit(param);
                this.dataList = selectRes.data.array;
                this.pageQuantity = selectRes.data.pageQuantity;
            },
            async sparePartPurchaseQuery() {
                var param = {
                    // ID:this.normalID,
                    // startApplyTime:,
                    // endApplyTime:,
                    // applyPerson:,
                    // warehouse:this.queryValue,
                    // department:,
                    // orderStatus:,
                    // authSort:,
                    // isAuth:,
                    // pageSize:this.pageSize,
                    // pageNumber:this.currentPage,
                    // userID:'user_aa',
                    userID: this.userID,
                }
                if (this.normalID) {
                    param.ID = this.normalID
                }
                const selectRes = await sparePartPurchaseQuery(param);
                this.queryData = selectRes.rows[0]
                this.editInline = this.queryData
                this.detailInline = this.queryData


            },
            //详情数据
            async sparePartPurchaseDetailQueryLimit() {
                var param = {
                    orderID: this.rowData.orderID,
                    // orderID:2,
                    pageSize: this.pageSize1,
                    pageNumber: this.currentPage1,
                }
                const selectRes = await sparePartPurchaseDetailQueryLimit(param);
                this.detailList = selectRes.rows;
                this.pageQuantity1 = selectRes.pageQuantity;
            },
            //点击行数据
            rowClick(row, event, column) {
                this.rowData = row
                console.log(this.rowData);
                this.sparePartPurchaseDetailQueryLimit()
            },
            //导出数据
          exportRepairRecord() {
                this.$exportExcel.exportDataTwo('repairPlanTable', '备件购置')
            },
             //详细
            dblclickGotoFaultDetails(row, column, event) {
                localStorage.setItem('oderId', row.ID);
                // this.$router.push({
                //     path: '/deviceManagement/maintenanceManagement/repairPlan/repairPlanDetails',
                //     // query: {
                //     //     id: id
                //     // },
                //     // query:{setid:111222}
                //     // params:{setid:111222}//地址栏中看不到参数的内容
                // })
            },
             //选中的行
            handleSelectionChange(val) {
                this.multipleSelection = val;
                //要批量删除的数组
                // this.deleteNum = []; //数组赋值前，需要清空一下数组
                this.deleteNum = "";
                // this.isAuth = '';
                for (var i = 0; i < val.length; i++) {
                    this.deleteNum += val[i].ID + ",";
                    // this.isAuth += val[i].userID + ",";
                }
                //去掉最后一个逗号
                if (this.deleteNum.length > 0) {
                    this.deleteNum = this.deleteNum.substr(0, this.deleteNum.length - 1);
                }

                var delNum = this.deleteNum;
                window.localStorage.setItem('delNum', this.deleteNum)
                // var isAuth =this.isAuth;
                // window.localStorage.setItem('isAuth')
            },

            handleChange(id) {
                this.tabIndex = id;
            },
            //删除
            deleteNum(id) {
                // if(this.multipleSelection.length>1){
                //     this.$message({
                //         message:"您只能选择一条数据",
                //         type:'warning'
                //     })
                // }else if(this.multipleSelection.length == 0){
                //     this.$message({
                //         message:"警告！请您选择一条数据",
                //         type:'warning'
                //     })
                // }else{
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.sparePartPurchaseDelete(id)
                    this.currentPage = this.delData.delData(this.dataList.length, this.pageQuantity, this
                        .currentPage);
                }).catch(() => {

                });
                // }
            },
            async sparePartPurchaseDelete(id) {
                console.log(this.multipleSelection);
                var param = {
                    ID: id
                }
                const selectRes = await sparePartPurchaseDelete(param);
                if (selectRes.messageEnable) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    })
                    this.sparePartPurchaseMultiAuthQueryLimit()
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: 'warning'
                    })
                    this.sparePartPurchaseMultiAuthQueryLimit()
                }
            },

            //新增
            gotoAdd() {
                this.$router.push({
                    path: '/deviceManagement/sparePartManagement/sparePartPurchase/addSparePart',
                })
            },

            //修改
            edit(ID) {
                // console.log("ID",this.editInline.ID);
                this.normalID = ID
                this.sparePartPurchaseQuery()

                this.dialogVisibleEdit = true;
                // this.editInline.warehouse=this.queryData.warehouse,
                // this.editInline.department=this.queryData.department,
                // this.editInline.remarks=this.queryData.remarks,
                // this.editInline.totalPrice=this.queryData.totalPrice
                // this.editInline= this.queryData
            },
            editConfirm() {
                this.sparePartPurchaseUpdate()
            },
            cancel(){
                this.dialogVisibleEdit = false;
                this.sparePartPurchaseMultiAuthQueryLimit();
            },
            confirm() {
                if(this.approvalListValue == undefined){
                    this.$message.error('请选择是否通过！');  
                }else{
                    this.sparePartPurchaseAuthorize()
                    this.dialogVisibleAuth = false
                    this.approvalListValue = undefined
                }
            },
            async sparePartPurchaseUpdate() {
                // var param = {
                // ID:this.editInline.ID,
                // warehouse:this.editInline.warehouse,
                // department:this.editInline.department,
                // remarks:this.editInline.remarks,
                // totalPrice:this.editInline.totalPrice,
                // }
                const selectRes = await sparePartPurchaseUpdate(this.editInline)
                this.updatedResult = selectRes.data.result
                if (this.updatedResult) {
                    this.$message({
                        message: selectRes.message,
                        type: "success"
                    })
                    this.dialogVisibleEdit = false
                } else {
                    this.$message({
                        message: selectRes.message,
                        type: "warning"
                    })
                    this.dialogVisibleEdit = false
                }

                this.sparePartPurchaseMultiAuthQueryLimit()
            },

            //详细
            gotoDetails(ID) {
                this.normalID = ID
                this.sparePartPurchaseQuery()
                this.dialogVisibleDetail = true;
                // this.detailInline.warehouse=this.queryData.warehouse,
                // this.detailInline.department=this.queryData.department,
                // this.detailInline.remarks=this.queryData.remarks,
                // this.detailInline.totalPrice=this.queryData.totalPrice,
                console.log(this.normalID, this.detailInline);
                // this.sparePartPurchaseQuery()


                // this.$router.push({
                //     path: '/deviceManagement/sparePartManagement/sparePartPurchase/sparePartPurchaseDetails',
                // })
            },

            // gotoFaultDetails2() {
            //     this.$router.push({
            //         path: '/deviceManagement/sparePartManagement/sparePartPurchase/sparePartPurchaseWarehousing',
            //     })
            // },
            //审批
            async multiAuthorizeResultQuery1() {
                const selectRes = await multiAuthorizeResultQuery1();
                this.approvalList = selectRes.array;
                // this.approvalListValue = selectRes.array[0].value;
                this.dialogVisibleAuth = true;

            },
            async sparePartPurchaseAuthorize() {
                var param = {
                    ID: this.rowList.ID,
                    userID: this.userID,
                    authResult: this.approvalListValue
                }
                const selectRes = await sparePartPurchaseAuthorize(param);
                if (selectRes.data.result) {
                    this.$message({
                        message: selectRes.message,
                        type: 'success'
                    });
                    this.dialogVisibleAuth = false;
                    this.sparePartPurchaseMultiAuthQueryLimit();
                } else {
                    this.$message.error(selectRes.message);
                    this.dialogVisibleAuth = false
                };
                this.authResult = selectRes
                // console.log(this.authResult);
            },
            approvalOperation(row) {
                this.rowList = row
                this.multiAuthorizeResultQuery1()
                this.dialogVisibleAuth = true
                console.log('aaaaa', this.rowList);
            },
            //时间格式
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
            //布尔值格式
            formatBoolean(row, column, cellValue) {
                var ret = '' //你想在页面展示的值
                if (cellValue) {
                    ret = "是" //根据自己的需求设定
                } else {
                    ret = "否"
                }
                return ret;
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.sparePartPurchaseMultiAuthQueryLimit(); //查询table数据的方法
            },
            handleCurrentChange1(val) {
                this.currentPage1 = val;
                this.sparePartPurchaseDetailQueryLimit(); //查询table数据的方法
            },
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .spanWidth {
        width: 80px;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>